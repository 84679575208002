import React from 'react';

export default function letsWorkTemplate(props) {
     return (     
    
    
        <div className="content-box bg-blue text-center text-white p-8 p-lg-10">
        <div className="row justify-content-center">
            <div className="col-md-6">
                <span className="icon-bulb bobble-anim font-xl text-pink d-block mb-10"></span>
                <h2 className="text-white">Let’s work together</h2>
                <>
                {props.tagline ? 
                <div dangerouslySetInnerHTML={{ __html: props.tagline }}/>
                :
                <p>Looking for a long term digital partner to help you realise your digital goals?</p>
                }
                </>
                
                <a href="/lets-talk" className="btn btn-primary">{props.buttonText ? props.buttonText : ('Contact Us Now') }</a>
                
            </div>
        </div>
        <div className="img-decor left-big">
            <img src={'/img-decor1.svg'} width="547" height="343" alt="" />
        </div>
    </div>
    
     )}