import React from 'react'
import Layout from '../components/layout'
import SEO from "../components/seo"
import { Fade } from 'react-awesome-reveal';
import LetsWorkTogether from '../components/letsWorkTogether';

import BannerImage from "../assets/images/intro-1.svg"
import UXImage from "../assets/images/img-text-1.jpg"
import UXIcon from "../assets/images/ico-1.svg"
import DesignImage from "../assets/images/img-text-2.jpg"
import DesignIcon from "../assets/images/ico-2.svg"
import DevImage from "../assets/images/img-text-3.jpg"
import DevIcon from "../assets/images/ico-3.svg"
import SupportImage from "../assets/images/img-text-4.jpg"
import SupportIcon from "../assets/images/ico-4.svg"


import Joomla from "../assets/images/joomla.svg"
import Drupal from "../assets/images/drupal.svg"
import WordPress from "../assets/images/wp.svg"


export default ({pageContext}) => (
	<Layout>	
		<SEO description="We work with organisations to understand their business and user needs and then create powerful solutions for current and prospective users." title={pageContext.title} />
		<nav aria-label="breadcrumb" className="breadcrumbs-section border-top border-bottom mb-0">
			<div className="container">
				<ol className="breadcrumb mb-0 py-2 px-0">
					<li className="breadcrumb-item">
					<a href="/">Home</a></li>
					<li className="breadcrumb-item active">{pageContext.title}</li>
				</ol>
			</div>
		</nav>
		<section className="intro-section pt-10 pb-5 pt-lg-26 pb-lg-6">
			<div className="container">
				<div className="row align-items-center justify-content-between">
					<div className="col-12 col-md-7 col-lg-6 mb-8 mb-md-0">
						<h1 className="mb-0">Realising your digital goals is what we love doing and here is how we love doing it.</h1>
					</div>
					<div className="col-12 col-md-5 col-lg-6 text-center text-md-right d-print-none">
						<div className="img-holder"> 
							<img src={BannerImage} alt="" width="" height="" />
						</div>
					</div>
				</div>
			</div>
		</section>
		

<section className="img-text-section pt-15 pt-lg-20">
<div className="container">
   <Fade triggerOnce="true" direction="top" className="img-text-row row align-items-center mb-15 mb-lg-20">
	  <div className="col-12 col-md-5 col-lg-6">
		 <div className="img-holder rounded-circle mb-4 mb-md-6 mb-lg-0"> 
			<img src={UXImage} srcset={UXImage} alt="" width="458" height="458" />
		 </div>
	  </div>
	  <div className="col-12 col-md-7 col-lg-6">
		 <div className="text-holder text-center text-md-left">
			<h2 className="h2 d-flex flex-wrap text-capitalize mb-4 mb-md-5 w-500 justify-content-center justify-content-md-start"> 
			   <span className="ico-holder mr-2 mr-md-4"> 
			   <img src={UXIcon} alt="" width="48" height="48" /> 
			   </span> 
			   <span className="text">User Experience</span>
			</h2>
			<p className="mb-4 mb-md-5">User experience or UX design describes how easy (or difficult) it is for someone to use your website or app. For example, how simple is it to find the information they’re looking for? How straightforward is it to complete a task – like submitting a form or downloading a file?</p>
			<p className="mb-4 mb-md-5">Your website’s user experience has a direct impact on the success of your website or application – and whether they do what you need them to do. A bad user experience design can frustrate users, while a positive user experience can help you achieve your organisational goals.</p>
		 </div>
	  </div>
   </Fade>
   <Fade triggerOnce="true" direction="top" className="img-text-row row flex-row-reverse align-items-center mb-15 mb-lg-20">
	  <div className="col-12 col-md-5 col-lg-6">
		 <div className="img-holder rounded-circle mb-4 mb-md-6 mb-lg-0"> 
			<img src={DesignImage} srcset={DesignImage} alt="" width="458" height="458" />
		 </div>
	  </div>
   	  <div className="col-12 col-md-7 col-lg-6">
	  <div className="text-holder text-center text-md-left">
		 <h2 className="h2 d-flex flex-wrap text-capitalize mb-4 mb-md-5 w-500 justify-content-center justify-content-md-start"> 
			<span className="ico-holder mr-2 mr-md-4"> 
			<img src={DesignIcon} alt="" width="48" height="48" /> </span> 
			<span className="text">Design</span>
		 </h2>
		 <p className="mb-4 mb-md-5">Put simply, digital design covers everything your customers see and do. It includes the look and feel of your organisation, the emotional impact you want to have and how your customers will use your site.</p>
		 <p className="mb-4 mb-md-5">Your customers access content on all kinds of devices, from desktop to tablet to mobile – so we take this into account when designing your digital project. It’s more than web design – digital design is considered and builds on the customer and user experience phase.</p>
		 <p>We know good design brings your project to life.We like to push the boundaries and create an innovative design solution that suits your unique needs. We apply our creativity and user experience knowledge to come up with original, memorable designs that meet your organisational goals – and test them along the way.</p>
		 <div className="btn-holder mt-3"> <a href="/folio/?by-service=27" className="btn btn-outline-primary with-arrow">See UI Case studies</a></div>
	  </div>
   </div>
</Fade>
<Fade triggerOnce="true" direction="top" className="img-text-row row align-items-center mb-15 mb-lg-20">
   <div className="col-12 col-md-5 col-lg-6">
	  <div className="img-holder rounded-circle mb-4 mb-md-6 mb-lg-0"> <img src={DevImage} srcset={DevImage} alt="" width="458" height="458" /></div>
   </div>
   <div className="col-12 col-md-7 col-lg-6">
	  <div className="text-holder text-center text-md-left">
		 <h2 className="h2 d-flex flex-wrap text-capitalize mb-4 mb-md-5 w-500 justify-content-center justify-content-md-start"> 
			<span className="ico-holder mr-2 mr-md-4"> <img src={DevIcon} alt="" width="48" height="48" /> </span> <span className="text">Development</span>
		 </h2>
		 <p className="mb-4 mb-md-5">Our expansive technical and digital development know-how addresses a wide range of technical needs. Success planning, creating bespoke open source platforms and bringing complex technical requirements to reality are just a few services we provide across the life cycle of our partnership.</p>
		 <p className="mb-4 mb-md-5">Led by your business objectives and how the technology will ultimately perform, we’ll find and create the most suitable solution.</p>
		 <p>See below for the Content management systems we are experts at developing with. Once we have understood your requirements we can recommend the optimal system to deliver results.</p>
		 <ul className="dev-list text-left pt-3 mb-10">
			<li><a href="https://www.webplace.com.au/wordpress-developers-melbourne/" className="position-relative d-flex flex-wrap align-items-center pl-4 pr-7 py-2"> 
			   <span className="ico-holder mr-4"> <img src={WordPress} alt="" width="35" height="35" /> </span> <span className="text fw-700">Wordpress</span> <span className="icon icon-arrow-right position-absolute"></span> </a>
			</li>
			<li><a href="https://www.webplace.com.au/drupal-developers-melbourne/" className="position-relative d-flex flex-wrap align-items-center pl-4 pr-7 py-2"> 
			   <span className="ico-holder mr-4"> <img src={Drupal} alt="" width="35" height="35" /> </span> <span className="text fw-700">Drupal</span> <span className="icon icon-arrow-right position-absolute"></span> </a>
			</li>
			<li><a href="https://www.webplace.com.au/joomla-web-development/" className="position-relative d-flex flex-wrap align-items-center pl-4 pr-7 py-2"> <span className="ico-holder mr-4"> 
			   <img src={Joomla} alt="" width="35" height="35" /> </span> <span className="text fw-700">Joomla</span> 
			   <span className="icon icon-arrow-right position-absolute"></span> </a>
			</li>
		 </ul>
	  </div>
   </div>
</Fade>
<Fade triggerOnce="true" direction="top" className="img-text-row row flex-row-reverse align-items-center mb-15 mb-lg-20">
   <div className="col-12 col-md-5 col-lg-6">
	  <div className="img-holder rounded-circle mb-4 mb-md-6 mb-lg-0"> 
		 <img src={SupportImage} srcset={SupportImage} alt="" width="458" height="458" />
	  </div>
   </div>
   <div className="col-12 col-md-7 col-lg-6">
	  <div className="text-holder text-center text-md-left">
		 <h2 className="h2 d-flex flex-wrap text-capitalize mb-4 mb-md-5 w-500 justify-content-center justify-content-md-start"> 
			<span className="ico-holder mr-2 mr-md-4"> <img src={SupportIcon} alt="" width="48" height="48" /> </span> 
			<span className="text">Support &amp; Maintenance</span>
		 </h2>
		 <p className="mb-4 mb-md-5">Ongoing digital services support comes from partnering with an agency that has a genuine interest in taking care of your needs. That’s us! Long after the project launches, we’ll continue to be a trusted partner that you can call on for technical support, system maintenance, design and content updates, and expert and informed advice as you need it.</p>
		 <p className="mb-4 mb-md-5">We’ll manage all your ongoing digital needs – as well as minimise downtime or loss or productivity in the case of unexpected issues.</p>
		 <ul className="dev-list text-left pt-3 mb-10">
			<li><a href="https://www.webplace.com.au/wordpress-support-and-maintenance/" className="position-relative d-flex flex-wrap align-items-center pl-4 pr-7 py-2"> 
				<span className="ico-holder mr-4"> <img src={WordPress} alt="" width="35" height="35" /> </span> 
				<span className="text fw-700">WordPress Support and Maintenance</span> <span className="icon icon-arrow-right position-absolute"></span> </a>
			</li>
		 	<li><a href="https://www.webplace.com.au/drupal-support-and-maintenance/" className="position-relative d-flex flex-wrap align-items-center pl-4 pr-7 py-2"> 
			   <span className="ico-holder mr-4"> <img src={Drupal} alt="" width="35" height="35" /> </span> 
			   <span className="text fw-700">Drupal Support and Maintenance</span> <span className="icon icon-arrow-right position-absolute"></span> </a>
			</li>
			<li><a href="https://www.webplace.com.au/joomla-support-and-maintenance/" className="position-relative d-flex flex-wrap align-items-center pl-4 pr-7 py-2"> 
			   <span className="ico-holder mr-4"> <img src={Joomla} alt="" width="35" height="35" /> </span> 
			   <span className="text fw-700">Joomla Support and Maintenance</span> <span className="icon icon-arrow-right position-absolute"></span> </a>
			</li>
		 </ul>
	  </div>
   </div>
</Fade>
<LetsWorkTogether  />
</div>
</section>


	</Layout>
);